import request from "@/api/config.js";

export function updateCompany(id, data) {
  return request({
    url: `company/${id}`,
    method: "put",
    data,
  });
}

export function findCompanyById(id) {
  return request({
    url: `company/${id}`,
    method: "get",
  });
}

export function getStudentsList(limit, offset) {
  return request({
    timeout: 15000,
    url: `company/getStudents/List/${limit}/${offset}`,
    method: "get",
  });
}
export function getStudentsListWithoutReadyTasks(limit, offset) {
  return request({
    timeout: 15000,
    url: `company/getStudents/List/WithoutReadyTasks/${limit}/${offset}`,
    method: "get",
  });
}

export function getAll() {
  return request({
    url: "company",
    method: "get",
  });
}

export function downloadFileWithStudents(data) {
  return request({
    url: "company/get/students/file/",
    method: "post",
    data,
  });
}

export function getPDFCertificate(user_id, task_id, vuz, startTime, endTime) {
  return request({
    url: `company/get/certificate/${user_id}/${task_id}/${vuz}/${startTime}/${endTime}`,
    method: "get", 
  });
}
