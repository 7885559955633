<template>
  <div id="ProfilePage">
    <Sidebar :isUserSidebar="false" />
    <div id="PageStart" ref="PageStart" class="ProfileContent">
      <!-- <p class="PageHeader">Добро пожаловать, Константин</p> -->
      <div class="ProfileBlocks">
        <!-- <div class="SearchPanel"></div> -->

        <span class="PageHeader">Ваш список организаций</span>

        <div class="Content">
          <div class="TableList">
            <div class="TableHead">
              <span
                v-for="(Text, Tindx) in tableHeader"
                :key="`${Text}${Tindx}`"
              >
                {{ Text }}
              </span>
            </div>
            <div
              class="TableElem"
              v-for="(partner, Uindx) in partnersList"
              :key="`${partner.id}${Uindx}`"
            >
              <div>
                <span>
                  {{ partner.name }}
                </span>
              </div>
              <div>
                <span>
                  {{ getRightData(partner.createdAt) }}
                </span>
              </div>
              <div>
                <span>
                  {{ partner.type }}
                </span>
              </div>
              <div>
                <span>
                  {{ getProjectsCounter(partner) }}
                </span>
              </div>
              <div>
                <span>
                  {{ getProjectJobsCounter(partner) }}
                </span>
              </div>
              <div>
                <span
                  class="ViewTasksButton"
                  @click="viewPartnerTasks(partner.id)"
                >
                  Просмотр задач
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Profile/Sidebar.vue";
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

import { getStudentsList } from "@/api/company.js";
import { getPartnersList } from "@/api/company_requests.js";

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableHeader: [
        "Название",
        "Дата регистрации",
        "Тип",
        "Количество проектов",
        "Количество задач",
        "Действие",
      ],

      partnersList: [],
    };
  },
  name: "OrgPartners",
  components: { Sidebar, ButtonGradient, ButtonGradientBorder },
  async created() {
    if (this?.user?.parent_company == null) {
      window.location.replace("/org/profile");
    }

    let _partners_list_resp = await getPartnersList(
      this.user.parent_company[0].id
    );

    let sortedArray = _partners_list_resp.data.sub_company.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    this.partnersList = sortedArray;
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },

  methods: {
    getRightData(date) {
      let arr = date.split("T")[0].split("-");

      return `${arr[2]}.${arr[1]}.${arr[0]}`;
    },

    getProjectsCounter(obj) {
      return obj?.users[0]?.n_projects != null
        ? obj.users[0].n_projects.length
        : 0;
    },
    getProjectJobsCounter(obj) {
      if (obj?.users[0]?.n_projects == null) {
        return 0;
      }

      let c = 0;
      for (let i of obj.users[0].n_projects) {
        if (i.Jobs != null) {
          c += i.Jobs.length;
        }
      }

      return c;
    },

    viewPartnerTasks(id) {
      window.location.replace(`/org/profile?viewID=${id}`);
    },
  },
};
</script>

<style scoped>
.ListIsEmpty {
  position: relative;

  margin-left: 30px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: normal;

  /* identical to box height */

  /* students/white */
  color: #e8e7ec;
}

/* .Modal {
    width: 35% !important;
  } */
#ProfilePage {
  position: relative;
  width: 100%;
  height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

#ProfilePage > .ProfileContent {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 23px 32px 23px 21px;
}

#ProfilePage > .ProfileContent > .ProfileBlocks {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 30px;

  margin: 0%;
  padding: 0%;

  overflow: hidden;
}

#ProfilePage > .ProfileContent > .ProfileBlocks > .profileBlock {
  position: relative;
  height: 100%;
}

#ProfilePage > .ProfileContent > .PageHeader {
  position: relative;
  width: 100%;

  margin-bottom: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  /* identical to box height */

  /* students/white */
  color: #e8e7ec;
}

.BlockColumn1,
.BlockColumn2 {
  flex-shrink: 0;
}
.BlockColumn1 {
  display: flex;
  flex-direction: column;
  width: 398px;
  margin-right: 32px;
  gap: 32px;
}
.BlockColumn2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}
.WaitingChangesWrapper {
  max-height: 350px;
}
</style>

<style scoped>
@media (max-width: 480px) {
  #ProfilePage .BlockColumn1 {
    height: fit-content !important;
  }

  #ProfilePage .BlockColumn2 {
    margin-bottom: 70px !important;
  }

  #ProfilePage .ProfileBlocks .BlockColumn1 > *:nth-child(1) {
    height: fit-content;
    width: 100% !important;
    margin-bottom: 0px !important;
  }
  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }

  .ProjectsList {
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 5rem;
  }
  .ProjectsList .Card {
    flex-basis: 100%;
  }
  .WaitingChangesWrapper {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .ProfileBlocks > .PageHeader {
    width: 100%;
    font-size: 26px;
    line-height: 24px;
    text-align: center;
  }

  .TableList > .TableHead > span {
    height: 100% !important;
  }

  .ProfileCard {
    height: fit-content !important;
  }
  .ProjectsList {
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 5rem;
  }
  .ProjectsList .Card {
    flex-basis: 100%;
  }
  .WaitingChangesWrapper {
    display: none !important;
  }

  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }

  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: nowrap;
    padding: 0% 32px 0% 21px;

    row-gap: 20px;
    column-gap: 40px;

    height: calc(100svh - 100px);
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks .profileBlock {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    padding: 0%;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: fit-content !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }
  .BlockColumn2 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: fit-content !important;

    order: 3;
  }
  .BlockColumn3 {
    width: 100%;
    max-width: 100%;

    height: auto !important;
    max-height: 400px;

    padding-bottom: 21px;
    order: 2;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: 150px;
    width: 100% !important;
    margin-bottom: 00px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    display: none;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex-basis: content;
    overflow: auto;
  }

  .Content {
    min-width: 990px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .ProfileCard {
    width: 100%;
  }
  .BlockColumn2 {
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: calc(50% - 16px);
    max-width: calc(50% - 16px);
    margin-right: 32px;
  }
  .BlockColumn2 {
    width: calc(50% - 16px);
    max-width: calc(50% - 16px);
  }

  .BlockColumn2 .ProjectsList > * {
    width: calc(100%);
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex-basis: content;
    overflow-y: auto;
  }

  .ProfileBlocks .SearchPanel > .LeftSide > span {
    font-size: 12px;
  }
  .ProfileBlocks .SearchPanel > .LeftSide > svg {
    width: 16px;
    height: 16px;
  }
  .ProfileBlocks .SearchPanel {
    gap: 24px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .BlockColumn2 {
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: 400px;
    max-width: 400px;
    margin-right: 32px;
  }
  .BlockColumn2 {
    width: 100%;
    max-width: 1322px;
  }

  .BlockColumn2 .ProjectsList > * {
    width: calc(100%);
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex-basis: content;
    overflow-y: auto;
  }
}
@media (min-width: 1400px) {
  .BlockColumn2 {
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: 400px;
    max-width: 400px;
    margin-right: 32px;
  }
  .BlockColumn2 {
    width: 100%;
    max-width: 1322px;
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex-basis: content;
    overflow-y: auto;
  }
}
</style>

<style scoped>
.CreateDate {
  margin-right: 15px;
}
.UsedDate {
  margin-right: 30px;
}
.GreenStatus {
  color: #1dd185;
}
.BlueStatus {
  color: #1d8ed1;
}
.BadgeStatus {
  position: relative;

  margin-right: 10px;

  border: 1px solid;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Modal > .Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.Modal > .Header {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-bottom: 20px;

  color: white;

  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 157.143% */
  letter-spacing: -0.28px;
}
.ModalWrapper {
  position: fixed;

  display: flex;

  width: 100%;
  height: 100vh;

  top: 0;
  left: 0;

  margin: 0;
  padding: 0;

  background-color: rgba(0, 0, 0, 0.75);
}

.ModalWrapper > .Modal {
  position: relative;

  padding: 28px 32px;

  width: 600px;
  max-width: 95%;

  height: fit-content;
  max-height: 95%;

  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  border-radius: 20px;
  background: #201e48;
}

.Modal > .ContentWrapper {
  position: relative;

  width: auto;
  height: auto;

  padding: 0px 8px 6px 6px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  overflow: auto;
}

.Modal .InputWrapper {
  position: relative;

  width: auto;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.Modal .InputWrapper > .Label {
  color: #e8e7ec;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}

.InputWrapper > input,
.InputWrapper > textarea {
  position: relative;

  width: 100%;
  height: 100%;

  padding: 14px 12px;

  border-radius: 4px;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );

  color: #c8c3de;
  text-overflow: ellipsis;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.InputWrapper > input:focus-visible,
.InputWrapper > textarea:focus-visible {
  outline: 2px solid rgb(130, 111, 238) !important;
  border-radius: 3px;
}

.InputWrapper > .TextHeader {
  position: relative;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  color: white;
}
.InputWrapper > .Textbody {
  position: relative;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  color: white;
}

.InputWrapper > textarea {
  min-height: 96px;
}

.ModalWrapper > .Modal > .CloseModalButton {
  position: absolute;

  cursor: pointer;

  width: 40px;
  height: 40px;

  right: 32px;
  top: 32px;

  background: url("./../../assets/img/Landing/ModalNewCloseButton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px 40px;

  z-index: 10;
}

.ButtonMarginLeft {
  margin-left: auto;
  margin-right: 6px;
}

.ProfileBlocks > .PageHeader {
  position: relative;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  color: white;
}
.Content {
  position: relative;
  display: flex;

  padding: 30px 20px;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  border-radius: 20px;
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;
  flex-basis: auto;
  flex-grow: 1;

  overflow: hidden;
}

.Content > .TableList {
  position: relative;
  display: flex;

  padding: 30px 20px;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;

  border-radius: 20px;
  background: #1f1e48;

  flex-shrink: 1;
  flex-basis: auto;
  flex-grow: 1;

  overflow: auto;
}

.Content > .ContentTabs > * {
  margin-top: auto;
  margin-bottom: auto;
}
.Content > .ContentTabs {
  position: relative;
  display: flex;

  margin-left: 30px;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
}
.Content > .ContentTabs > span {
  position: relative;
  display: block;

  cursor: pointer;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}
.Content > .ContentTabs > span.TabActive {
  font-weight: 700;
}

.Content > .ContentTables {
  position: relative;
  display: flex;

  width: 100%;
  height: auto;

  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;

  overflow: auto;
}
.Content > .ContentTables > .ActiveLinksContent,
.Content > .ContentTables > .UsedLinksContent {
  position: relative;
  display: flex;

  padding: 20px 10px 20px 0%;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
}

.Content > .ContentTables > .UsedLinksContent {
  gap: 30px;
}

.ContentTables > .ActiveLinksContent > .LinkNotUsed > *,
.ContentTables > .UsedLinksContent > .LinkNotUsed > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ContentTables > .ActiveLinksContent > .LinkNotUsed,
.ContentTables > .UsedLinksContent > .LinkNotUsed {
  position: relative;
  display: flex;

  padding: 20px 30px;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;

  border-radius: 20px;
  background: #1f1e48;
}
.ActiveLinksContent > .LinkNotUsed > .CodeDesc,
.UsedLinksContent > .LinkNotUsed > .CodeDesc {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 39px;

  color: rgb(216, 216, 216);
}

.Content > .ContentTables > .ActiveLinksContent > *:nth-child(1) {
  margin-bottom: 30px;
  margin-left: 30px;
}
.Content
  > .ContentTables
  > .ActiveLinksContent
  > *:not(:nth-child(1)):not(:nth-child(2)) {
  margin-top: 15px;
}

.TableList > .TableHead {
  position: relative;

  width: 100%;
  height: fit-content;

  display: grid;
  grid-template-columns: 20% 20% 20% 15% 15% 10%;

  border-bottom: 1px solid #413e74;
}
.TableList > .TableHead > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TableList > .TableHead > span {
  position: relative;
  display: block;

  padding-bottom: 10px;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 155%;
}
.TableList > .TableHead > span:not(:nth-child(1)),
.TableList > .TableElem > div:not(:nth-child(1)) {
  padding-left: 10px;
  border-left: 1px solid #413e7440;
}
.TableList > .TableHead > span:not(:nth-last-child(1)),
.TableList > .TableElem > div:not(:nth-last-child(1)) {
  padding-right: 10px;
}

.TableList > .TableHead > span:nth-child(1),
.TableList > .TableElem > div:nth-child(1) {
  padding-left: 30px;
}

.TableList > .TableElem {
  position: relative;

  width: 100%;
  height: fit-content;

  display: grid;
  grid-template-columns: 20% 20% 20% 15% 15% 10%;

  border-bottom: 1px solid #413e74;
}
.TableList > .TableElem > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TableList > .TableElem > div {
  position: relative;
  display: flex;

  padding: 10px 0%;

  width: 100%;
  min-height: 66.5px;
  height: 100%;
}
.TableList > .TableElem > div > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TableList > .TableElem > div > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;

  word-wrap: break-word;
  word-break: normal;
}

.ViewTasksButton {
  text-decoration: underline;
  cursor: pointer;
}
</style>
